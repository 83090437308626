<template>
  <div class="StreamOnboardingStep1">
    <heading class="StreamOnboardingStep__counter" size="7" tag="div">
      {{ $t('pages.stream_onboarding.step_count', { current:currentStep , total:totalSteps }) }}
    </heading>
    <heading size="3">
      {{ $t('pages.stream_onboarding.step_1.heading') }}
    </heading>
    <heading size="5">
      {{ $t('pages.stream_onboarding.step_1.sub_heading') }}
    </heading>
    <form @submit.prevent="handleSubmit">
      <form-wrapper :validator="validator">
        <form-field
          :label="$t('forms.first_name')"
          name="first_name"
        >
          <input
            v-model="validator.$model"
            slot-scope="{ validator, attrs }"
            v-bind="attrs"
            class="input"
            type="text"
          >
        </form-field>
        <form-field
          :label="$t('forms.last_name')"
          name="last_name"
        >
          <input
            v-model="validator.$model"
            slot-scope="{ validator, attrs }"
            v-bind="attrs"
            class="input"
            type="text"
          >
        </form-field>
        <form-field
          :label="$t('forms.email')"
          name="email"
        >
          <input
            v-model.lazy="validator.$model"
            slot-scope="{ validator, attrs }"
            v-bind="attrs"
            class="input"
            type="email"
          >
        </form-field>
      </form-wrapper>
      <div class="m-t-m has-text-right">
        <v-button class="is-primary m-b-s">
          {{ $t('pages.stream_onboarding.step_1.next_button') }}
        </v-button>
        <div>
          {{ $t('pages.stream_onboarding.step_1.small_print') }}
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import OnboardingStepPrototype, { createComputedFormField } from '@/components/onboarding/OnboardingStepPrototype'

/**
 * @module StreamOnboardingStep1
 */
export default {
  name: 'StreamOnboardingStep1',
  extends: OnboardingStepPrototype,
  computed: {
    first_name: createComputedFormField('first_name'),
    last_name: createComputedFormField('last_name'),
    email: createComputedFormField('email')
  },
  methods: {
    handleSubmit () {
      if (this.validate()) {
        this.$ma.trackEvent({
          action: 'Soft-conversion',
          properties: {
            type: 'Free Media Monitoring',
            firstName: this.first_name,
            lastName: this.last_name,
            email: this.email
          }
        })
        this.$ma.trackEvent({
          category: 'free_monitoring_flow',
          action: 'step2',
          label: 'version5'
        }, ['segment', 'facebook'])
        this.goNext()
      }
    }
  }
}
</script>
