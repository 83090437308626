var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"StreamOnboardingStep1"},[_c('heading',{staticClass:"StreamOnboardingStep__counter",attrs:{"size":"7","tag":"div"}},[_vm._v(" "+_vm._s(_vm.$t('pages.stream_onboarding.step_count', { current:_vm.currentStep , total:_vm.totalSteps }))+" ")]),_c('heading',{attrs:{"size":"3"}},[_vm._v(" "+_vm._s(_vm.$t('pages.stream_onboarding.step_1.heading'))+" ")]),_c('heading',{attrs:{"size":"5"}},[_vm._v(" "+_vm._s(_vm.$t('pages.stream_onboarding.step_1.sub_heading'))+" ")]),_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_c('form-wrapper',{attrs:{"validator":_vm.validator}},[_c('form-field',{attrs:{"label":_vm.$t('forms.first_name'),"name":"first_name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validator = ref.validator;
var attrs = ref.attrs;
return _c('input',_vm._b({directives:[{name:"model",rawName:"v-model",value:(validator.$model),expression:"validator.$model"}],staticClass:"input",attrs:{"type":"text"},domProps:{"value":(validator.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(validator, "$model", $event.target.value)}}},'input',attrs,false))}}])}),_c('form-field',{attrs:{"label":_vm.$t('forms.last_name'),"name":"last_name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validator = ref.validator;
var attrs = ref.attrs;
return _c('input',_vm._b({directives:[{name:"model",rawName:"v-model",value:(validator.$model),expression:"validator.$model"}],staticClass:"input",attrs:{"type":"text"},domProps:{"value":(validator.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(validator, "$model", $event.target.value)}}},'input',attrs,false))}}])}),_c('form-field',{attrs:{"label":_vm.$t('forms.email'),"name":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validator = ref.validator;
var attrs = ref.attrs;
return _c('input',_vm._b({directives:[{name:"model",rawName:"v-model.lazy",value:(validator.$model),expression:"validator.$model",modifiers:{"lazy":true}}],staticClass:"input",attrs:{"type":"email"},domProps:{"value":(validator.$model)},on:{"change":function($event){return _vm.$set(validator, "$model", $event.target.value)}}},'input',attrs,false))}}])})],1),_c('div',{staticClass:"m-t-m has-text-right"},[_c('v-button',{staticClass:"is-primary m-b-s"},[_vm._v(" "+_vm._s(_vm.$t('pages.stream_onboarding.step_1.next_button'))+" ")]),_c('div',[_vm._v(" "+_vm._s(_vm.$t('pages.stream_onboarding.step_1.small_print'))+" ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }